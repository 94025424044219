<template>
  <div>
    <svg
      v-if="!on"
      class="w-4 h-4 text-gray-500 dark:text-gray-400"
      :class="[[classNames]]"
      fill="currentColor"
      x="0px"
      y="0px"
      viewBox="0 0 489.242 489.242"
      style="enable-background: new 0 0 489.242 489.242"
      xml:space="preserve"
    >
      <g>
        <g>
          <path
            d="M416.321,171.943c0-97.8-82.2-176.9-181-171.7c-89.5,5.2-160.3,79.1-162.4,168.6c0,44.7,16.6,86.3,45.8,118.6
			c47.7,51.1,41.6,110.3,41.6,110.3c0,11.4,9.4,20.8,20.8,20.8h126.9c11.4,0,20.8-9.4,21.8-20.8c0,0-7-57.7,40.6-109.2
			C399.621,257.243,416.321,215.643,416.321,171.943z M288.321,377.943h-87.4c-2.1-42.7-20.8-84.3-51-116.5
			c-22.9-25-34.3-57.2-34.3-90.5c1-68.7,54.1-124.8,122.8-129c74.9-4.2,137.3,56.2,137.3,130c0,32.3-12.5,64.5-35.4,88.4
			C309.121,293.643,290.421,335.243,288.321,377.943z"
          />
          <path
            d="M281.021,447.643h-73.9c-11.4,0-20.8,9.4-20.8,20.8s9.4,20.8,20.8,20.8h73.9c11.4,0,20.8-9.4,20.8-20.8
			C301.821,457.043,292.521,447.643,281.021,447.643z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
    <svg
      v-else
      class="w-4 h-4 text-gray-500 dark:text-gray-400"
      :class="[[classNames]]"
      x="0px"
      y="0px"
      width="417.075px"
      height="417.075px"
      viewBox="0 0 417.075 417.075"
      style="enable-background: new 0 0 417.075 417.075"
      xml:space="preserve"
    >
      <g>
        <g>
          <path
            d="M346.717,131.279C346.717,58.89,284.731,0,208.538,0S70.358,58.891,70.358,131.279c0,50.467,19.548,79.123,38.464,106.849
			c15.547,22.779,31.605,46.33,33,77.959H275.34c1.402-31.604,17.43-55.146,32.961-77.938
			C327.188,210.436,346.717,181.779,346.717,131.279z"
          />
          <path
            d="M143.258,368.438c0,9.425,7.641,17.064,17.066,17.064h10.431c3.223,17.95,18.906,31.573,37.783,31.573
			c18.876,0,34.56-13.623,37.783-31.573h10.432c9.424,0,17.066-7.641,17.066-17.064v-28.795H143.258V368.438z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    classNames: {
      type: String,
      default: 'fill-current',
    },
    on: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
