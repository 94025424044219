<template>
  <svg
    id="Capa_1"
    class="w-5 h-5 text-gray-500 dark:text-gray-400"
    fill="currentColor"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 500.442 500.442"
    xml:space="preserve"
  >
    <g>
      <g>
        <g>
          <path
            d="M0,118.068v264.307h500.442V118.068H0z M156.216,275.038c3.387,3.904,6.105,7.075,8.089,9.448l-21.786,14.366
				l-19.069-31.515c-5.738,10.332-12.425,21.161-19.91,32.766l-21.506-16.674c6.924-7.658,14.021-14.905,21.506-22.002
				c2.934-2.783,4.832-4.681,5.544-5.35c-2.33-0.453-9.146-1.985-20.406-4.789c-8.111-2.071-13.46-3.43-16.027-4.444l8.693-25.108
				c12.446,5.048,23.491,10.721,33.111,16.717c-2.222-15.574-3.387-28.258-3.387-38.051h25.065c0,6.924-1.294,19.673-3.84,38.202
				c1.941-0.755,5.954-2.567,12.231-5.544c8.52-3.883,16.415-7.183,23.642-9.923l7.571,25.907
				c-10.548,2.33-22.779,4.681-36.67,6.967L156.216,275.038z M282.232,275.103c3.451,3.861,6.148,7.01,8.132,9.448l-21.786,14.366
				l-19.09-31.536c-5.803,10.332-12.511,21.118-19.953,32.744l-21.42-16.631c6.881-7.679,13.999-14.927,21.463-22.024
				c2.912-2.783,4.832-4.638,5.565-5.393c-2.373-0.431-9.168-1.984-20.406-4.789c-8.154-2.049-13.482-3.43-16.006-4.444l8.65-25.065
				c12.446,5.069,23.469,10.721,33.111,16.717c-2.265-15.553-3.343-28.236-3.343-38.029h25.022c0,6.903-1.273,19.651-3.818,38.202
				c1.877-0.777,5.954-2.588,12.231-5.544c8.542-3.883,16.415-7.205,23.62-9.966l7.55,25.928c-10.527,2.33-22.8,4.681-36.606,6.967
				L282.232,275.103z M408.292,275.103c3.365,3.861,6.105,7.01,8.089,9.448l-21.786,14.366l-19.047-31.536
				c-5.824,10.332-12.425,21.118-19.975,32.744l-21.441-16.631c6.816-7.679,13.935-14.927,21.441-22.024
				c2.955-2.739,4.897-4.638,5.608-5.35c-2.351-0.453-9.232-1.985-20.428-4.81c-8.089-2.071-13.439-3.451-15.962-4.465l8.671-25.087
				c12.425,5.069,23.469,10.699,33.068,16.717c-2.243-15.553-3.322-28.236-3.322-38.029h25.022c0,6.903-1.337,19.651-3.883,38.202
				c1.941-0.777,5.932-2.61,12.209-5.544c8.542-3.883,16.437-7.205,23.642-9.966l7.593,25.928
				c-10.483,2.308-22.843,4.681-36.649,6.946L408.292,275.103z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>
